<template>
  <GridHolder v-if="channels.length > 0" class="header-margin">
    <ChannelGrid :channels="channels" :shop-items="shopItems" :paper-items="paperItems" />
  </GridHolder>
</template>

<script>
import getChannelData from '@/components/channel/getChannelData'

import GridHolder from '@/components/ui/grid/grid'
import ChannelGrid from '@/components/channel/channel-grid'

import { generateTitle, generateMetaTags } from '@/utils/head-generators'

export default {
  components: {
    GridHolder,
    ChannelGrid,
  },
  asyncData(params) {
    return getChannelData(params)
  },
  data() {
    return {
      channels: [],
      shopItems: [],
      paperItems: [],
    }
  },
  head() {
    return {
      title: generateTitle(
        this.channels[0].attributes.pageTitle ? this.channels[0].attributes.pageTitle : '',
        this.$config.pageTitle
      ),
      meta: generateMetaTags(
        this.$config.pageTitle,
        this.$config.baseUrl,
        this.$config.imageServiceBaseUrls,
        this.channels[0].attributes.pageTitle ? this.channels[0].attributes.pageTitle : '',
        this.channels[0].attributes.description ? this.channels[0].attributes.description : '',
        this.$route.path,
        '/og-default.jpg',
        100,
        100
      ),
    }
  },
}
</script>
